html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background: #f5f5f5;
}

a {
  text-decoration: none;
}

label {
  font-weight: bold;
}

.hr {
  background: gray;
  width: 100%;
  height: 1px;
}

.Toastify__toast--default {
  background: black !important;
  color: white !important;
}

#cover-spin {
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  position: fixed;
}

.loadingSpinner {
  margin: auto;
  display: table;
  padding-top: 200px;
}

.hengelMenuItemDisable {
  display: none !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  padding: 0.7rem 0.8rem !important;
}

.p-card-content {
  padding: 0 !important;
}

.p-datatable-thead tr th,
.p-panel-titlebar,
.p-datatable-header,
.p-panel-header,
.p-paginator,
.p-dialog-header .p-datatable-scrollable-header,
.p-dialog-header-icon:enabled:hover {
  background-color: #007ad7 !important;
  color: #f5f5f5 !important;
}

.p-dialog-header,
.p-dialog-title,
.p-paginator-current {
  background-color: #007ad7 !important;
  color: #f5f5f5 !important;
  padding: 10px !important;
  font-size: 1rem !important;
}

.p-sortable-column-icon,
.p-dialog-header-icon {
  color: #f5f5f5 !important;
}

.p-dialog-header-icon :hover {
  color: #495057 !important;
}

.p-paginator-element {
  color: #f5f5f5 !important;
  background-color: #a7b7c4 !important;
}

.p-component {
  font-size: 0.9rem !important;
}

.p-dialog-content {
  padding: 1rem !important;
}

.p-dialog-footer {
  padding-top: 1rem !important;
  background-color: #ededed !important;
}

.p-table-copia {
  border-collapse: collapse;
  width: 100%;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: rgb(49, 49, 49);
  border-radius: 5px;
}

.p-table-copia td,
.p-table-copia th {
  border: 1px solid rgb(189, 186, 186);
  padding: 5px;
}

.p-table-copia tr:nth-child(even) {
  background-color: #c8dcfa;
}

.p-table-copia tr:hover {
  background-color: #4dadf7;
}

.p-table-copia th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  background-color: #007ad7;
  color: white;
}

.caixaFiltroPaginas {
  border: 1px solid darkgray;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 25px;
}

.line {
  width: 100%;
  border-bottom: 1px solid;
  padding-top: 5px;
}

.footerPagina {
  padding-top: 5%;
}

.p-dropdown-filter-container {
  width: 100%;
}

.p-timepicker {
  padding: 0 !important;
}

.p-column-filter {
  width: 100%;
}

.p-card {
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 36%) !important;
}

.p-autocomplete-panel .p-autocomplete-items {
  position: fixed;
  background-color: white;
  border: 1px solid lightgray;
}

.hengel-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.hengel-tooltip .hengel-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.hengel-tooltip .hengel-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.hengel-tooltip:hover .hengel-tooltiptext {
  visibility: visible;
  opacity: 1;
}

.p-panel .p-panel-header .p-panel-header-icon {
  background-color: white !important;
}

html,
body,
#hengel-google-maps {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px
}

.container {
  margin: 0 0.5rem;
}

@media (min-width: 1100px) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .p-grid-chart {
    display: block !important;
  }

  .menu-cima-hengel {
    display: none;
  }

  .p-datatable .p-datatable-tbody>tr {
    border-bottom: 1px solid black !important;
  }

}

@media (min-width: 767px) {
  .tieredmenu-cima-hengel {
    display: none;
  }

  .p-datatable .p-datatable-tbody>tr:hover {
    background-color: #4dadf7 !important;
  }

  .p-datatable .p-datatable-tbody>tr:nth-child(even) {
    background-color: #c8dcfa !important;
  }

}

.p-datatable-virtual-scroller.custom-scroller {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: scroll;
  display: block;
}

.p-submenu-list {
  z-index: 2 !important;
}

.custom-row-height .p-datatable-tbody > tr {
  height: 50px; /* Altere o valor para a altura desejada */
}

.custom-form-grid .p-fluid > input,
.custom-form-grid .p-fluid > .p-inputnumber,
.custom-form-grid .p-fluid > .p-dropdown {
  width: 100%;    /* Ocupa a largura total da coluna */
  min-width: 150px; /* Largura mínima para inputs menores */
}

/* Estilo para o contêiner com o scroll */
.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #b0b0b0 #f0f0f0; /* Cinza para o scroll e cinza claro para o fundo */
}

/* Estilos específicos para o scroll em navegadores baseados em WebKit (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
  height: 6px; /* Altura mais fina para o scroll horizontal */
}

.scroll-container::-webkit-scrollbar-track {
  background: #f0f0f0; /* Cor do fundo do scroll */
  border-radius: 10px; /* Bordas arredondadas */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #b0b0b0; /* Cor cinza para o scroll */
  border-radius: 10px; /* Bordas arredondadas */
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #808080; /* Cinza mais escuro ao passar o mouse */
}
