.hengel-tabel-no-resp {
    border-collapse: collapse;
    width: 100%;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    color: rgb(49, 49, 49);
}

.hengel-tabel-no-resp th {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    background-color: #007ad7;
    color: white;
}

.hengel-tabel-no-resptfoot tr {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    background-color: #007ad7;
    color: white;
    font-weight: 600;
}

.hengel-tabel-no-resp tfoot tr:hover {
    background-color: #007ad7;
}

.hengel-tabel-no-resp tfoot td {
    padding: 12px;
}

.hengel-tabel-no-resp tr {
    background-color: white;
}

.hengel-tabel-no-resp tr:nth-child(even) {
    background-color: #c8dcfa;
}

.hengel-tabel-no-resp tr:hover {
    background-color: #4dadf7;
}

.hengel-tabel-no-resp td {
    padding: 10px 5px;
}

.hengel-tabel-no-resp th {
    padding: 12px;
}

.hengel-tabel-no-resp-border td {
    border: 1px solid rgb(189, 186, 186);
}

.hengel-tabel-no-resp-border th {
    border: 1px solid rgb(189, 186, 186);
}


