.containerLogin {
  /* background-image: url("../../static/images/thisBanner.jpeg"); */
  background-size: cover;
  height: 1000px;
  position: relative;
}

.containerBluer {
  display: flex;
  background: rgba(40, 57, 101, 0.753);
  height: 100%;
}

.login-wrap {
  width: 100%;
  margin: auto;
  max-width: 400px;
  min-height: 550px;
  position: relative;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  margin-top: 20px;
}

.login-html {
  width: 100%;
  /* height: 100%; */
  position: absolute;
  padding: 20px 20px 0px 20px;
  /* cor fundo login */
  background: rgba(40, 57, 101, 0.9);
  text-transform: uppercase;
  border-radius: 5px;
}

.login-html .tab {
  font-size: 16px;
  padding-bottom: 5px;
  margin: 0 10px 10px 0;
  display: inline-block;
  border-bottom: 2px solid transparent;
}

.tab {
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.login-form .group {
  margin-bottom: 15px;
}

.login-form .group .label,
.login-form .group .input,
.login-form .group .button {
  width: 100%;
  color: #fff;
  display: block;
}

.login-form .group .button {
  text-transform: uppercase;
  font-size: 14px;
}

.login-form .group .input,
.login-form .group .button {
  border: none;
  padding: 15px 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
}

.login-form .group .input:focus,
.login-form .group .button:focus {
  outline: none;
}

.login-form .group .label {
  font-size: 12px;
}

.login-form .group .button {
  background: #1161ee;
}

.hrLogin {
  height: 2px;
  margin: 10px 0 10px 0;
  background: rgba(255, 255, 255, 0.2);
}

.imgHengel {
  height: 180px;
  width: 180px;
  border-radius: 200px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
  border: 2px solid rgb(11, 38, 105);
}

.sapimg {
  height: 40px;
  margin-left: "200px";
  display: block;
  display: inline-block;
}

.tipoLogin {
  display: flex;
  justify-content: space-evenly;
  color: white;
  font-size: 14px;
}