.hengel-tabel {
    border-collapse: collapse;
    width: 100%;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    color: rgb(49, 49, 49);
}

.hengel-tabel th {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    background-color: #007ad7;
    color: white;
}

.hengel-tabel tfoot tr {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    background-color: #007ad7;
    color: white;
    font-weight: 600;
}

.hengel-tabel tfoot tr:hover {
    background-color: #007ad7;
}

.hengel-tabel tfoot td {
    padding: 12px;
}

.hengel-tabel tr {
    background-color: white;
}

.hengel-tabel tr:nth-child(even) {
    background-color: #c8dcfa;
}

.hengel-tabel tr:hover {
    background-color: #4dadf7;
}

.hengel-tabel td {
    /* border: 1px solid rgb(189, 186, 186); */
    padding: 10px 5px;
}

.hengel-tabel th {
    padding: 12px;
}

.hengel-table-label-col {
    display: none !important;
}

@media screen and (max-width: 600px) {
    .hengel-tabel {
        margin: 10px;
    }
    .hengel-tabel thead {
        display: none;
    }
    .hengel-tabel td {
        display: block;
        padding: 5px;
        width: 100%;
    }
    .hengel-tabel tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }
    .hengel-tabel td,
    .hengel-tabel th {
        border: none;
        text-align: left !important;
    }
    .hengel-table-label-col {
        display: inline-block !important;
    }
}
